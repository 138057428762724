import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DoorService {

    private _doorName = new BehaviorSubject<boolean>(false);
    doorName$ = this._doorName.asObservable();

    constructor() {
        var name = localStorage.getItem('_doorName');
        if (name) {
            this.setDoorName(name);
        }
    }


    setDoorName(name) {
        localStorage.setItem('_doorName', name);
        this._doorName.next(name);
    }
}
