import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {VexRoutes} from "../@vex/interfaces/vex-route.interface";

const childrenRoutes: VexRoutes = [
    {
        path: 'dashboard',
        component: CustomLayoutComponent,
        children: []
    },
    {
        path: 'doors',
        children: [{
            path: 'list',
            loadChildren: () => import('./pages/modules/doors/list/list.module').then(m => m.ListModule),
            data: {
                toolbarShadowEnabled: false,
                toolbarTitle: "Mes portes"
            }
        },
            {
                path: 'home',
                loadChildren: () => import('./pages/modules/doors/home/home.module').then(m => m.HomeModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Accueil",
                    defaultBack: '/doors/list'
                }
            },
            {
                path: 'remote-control',
                loadChildren: () => import('./pages/modules/doors/remote-control/remote-control.module').then(m => m.RemoteControlModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Sélecteur",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'clock-mode',
                loadChildren: () => import('./pages/modules/doors/clock-mode/clock-mode.module').then(m => m.ClockModeModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Mode Horloge",
                    defaultBack: '/doors/remote-control'
                }
            },
            {
                path: 'state',
                loadChildren: () => import('./pages/modules/doors/state/state.module').then(m => m.StateModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Etat & Maintenance",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'eco',
                loadChildren: () => import('./pages/modules/doors/eco/eco.module').then(m => m.EcoModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Mode Eco",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'imagin',
                loadChildren: () => import('./pages/modules/doors/imagin/imagin.module').then(m => m.ImaginModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Imagin",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'counter',
                loadChildren: () => import('./pages/modules/doors/counter/counter.module').then(m => m.CounterModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Comptage",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'pointer',
                loadChildren: () => import('./pages/modules/doors/pointer/pointer.module').then(m => m.PointerModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Pointage",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'loyalty',
                loadChildren: () => import('./pages/modules/doors/loyalty/loyalty.module').then(m => m.LoyaltyModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "Fidélisation",
                    defaultBack: '/doors/home'
                }
            },
            {
                path: 'maintenance',
                loadChildren: () => import('./pages/modules/doors/maintenance/maintenance.module').then(m => m.MaintenanceModule),
                data: {
                    toolbarShadowEnabled: false,
                    toolbarTitle: "SAV",
                    defaultBack: '/doors/home'
                }
            }]
    },
];


const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
    },
    {
        path: '',
        component: CustomLayoutComponent,
        children: childrenRoutes
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'corrected',
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
