import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {LayoutService} from '../../services/layout.service';
import icMenu from '@iconify/icons-ic/twotone-menu';
//import icArrow from '@iconify/icons-fa-solid/arrow-left';
import icArrow from '@iconify/icons-ic/arrow-back';
import {ConfigService} from '../../services/config.service';
import {map} from 'rxjs/operators';
import {NavigationService} from '../../services/navigation.service';
import {PopoverService} from '../../components/popover/popover.service';
import {MegaMenuComponent} from '../../components/mega-menu/mega-menu.component';
import {Router} from "@angular/router";

@Component({
    selector: 'vex-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

    @Input() mobileQuery: boolean;
    @Input() toolbarTitle: string;
    @Input() doorName: string;
    @Input() defaultBack: string;

    @Input()
    @HostBinding('class.shadow-b')
    hasShadow: boolean;

    navigationItems = this.navigationService.items;

    isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
    isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
    isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

    icMenu = icMenu;
    icArrow = icArrow;

    constructor(private layoutService: LayoutService,
                private configService: ConfigService,
                private navigationService: NavigationService,
                private router: Router,
                private popoverService: PopoverService) {


    }

    ngOnInit() {
    }

    openQuickpanel() {
        this.layoutService.openQuickpanel();
    }

    openSidenav() {
        this.layoutService.openSidenav();
    }

    openMegaMenu(origin: ElementRef | HTMLElement) {
        this.popoverService.open({
            content: MegaMenuComponent,
            origin,
            position: [
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });
    }

    openSearch() {
        this.layoutService.openSearch();
    }

    back() {
        this.router.navigateByUrl(this.defaultBack);
    }
}
