import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from '../../@vex/services/layout.service';
import theme from '../../@vex/utils/tailwindcss';
import {filter, map, startWith} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {checkRouterChildsData, getRouterChildsData} from '../../@vex/utils/check-router-childs-data';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ConfigService} from '../../@vex/services/config.service';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {SidebarComponent} from '../../@vex/components/sidebar/sidebar.component';
import {DoorService} from "../providers/door.service";

@Component({
    selector: 'vex-custom-layout',
    templateUrl: './custom-layout.component.html',
    styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit, OnDestroy {

    sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
    doorName$ = this.doorService.doorName$;
    isDesktop$ = this.breakpointObserver.observe(`(min-width: ${theme.screens.lg})`).pipe(
        map(state => state.matches)
    );

    toolbarShadowEnabled$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
    );
    toolbarTitle$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => getRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarTitle)));
    defaultBack$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => getRouterChildsData(this.router.routerState.root.snapshot, data => data.defaultBack)));


    @ViewChild('configpanel', {static: true}) configpanel: SidebarComponent;

    constructor(private layoutService: LayoutService,
                private configService: ConfigService,
                private doorService: DoorService,
                private breakpointObserver: BreakpointObserver,
                private router: Router) {
    }

    ngOnInit() {
        this.layoutService.configpanelOpen$.pipe(
            untilDestroyed(this)
        ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
    }

    ngOnDestroy(): void {
    }
}
