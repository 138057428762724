import { ActivatedRouteSnapshot } from '@angular/router';
import { VexRouteData } from '../interfaces/vex-route.interface';

export function checkRouterChildsData(route: ActivatedRouteSnapshot & { data?: VexRouteData }, compareWith: (data: VexRouteData) => boolean) {
  if (compareWith(route.data)) {
    return true;
  }

  if (!route.firstChild) {
    return false;
  }

  return checkRouterChildsData(route.firstChild, compareWith);
}


export function getRouterChildsData(route: ActivatedRouteSnapshot & { data?: VexRouteData }, compareWith: (data: VexRouteData) => any) {
  if(compareWith(window.history.state))
    return compareWith(window.history.state);
  if (compareWith(route.data)) {
    return compareWith(route.data);
  }

  if (!route.firstChild) {
    return false;
  }

  return getRouterChildsData(route.firstChild, compareWith);
}
