import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VexModule} from '../@vex/vex.module';
import {HttpClientModule} from '@angular/common/http';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgImageSliderModule} from 'ng-image-slider';
import {DoorService} from "./providers/door.service";

@NgModule({
    declarations: [AppComponent],
    imports: [
        MatDatepickerModule,
        MatNativeDateModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgImageSliderModule,
        HttpClientModule,
        NgxMaterialTimepickerModule.setLocale('fr-FR'),
        // Vex
        VexModule,
        CustomLayoutModule,
    ],
    providers: [
        DoorService,
        MatDatepickerModule,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
