<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
        <mat-icon [icIcon]="icMenu"></mat-icon>
    </button>
    <button (click)="back()" mat-icon-button type="button" *ngIf="defaultBack">
        <mat-icon [icIcon]="icArrow"></mat-icon>
    </button>

    <a *ngIf="isVerticalLayout$ | async"
       [routerLink]="['/']"
       class="ltr:mr-4 rtl:ml-4 block"
       fxLayout="row"
       fxLayoutAlign="start center">
        <img class="w-8 select-none" src="assets/img/demo/logo.svg">
        <h1 [fxHide]="mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
    </a>


    <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
         [fxHide]="mobileQuery"
         class="px-gutter"
         fxFlex="none"
         fxLayout="row"
         fxLayoutAlign="start center">
        <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
    </div>

    <h3 fxFlex *ngIf="toolbarTitle"
        fxLayout="column"
        fxLayoutAlign="center center">
        <div *ngIf="doorName">{{ doorName }}</div>
        <div class="toolbar-title">{{ toolbarTitle }}</div>
    </h3>


</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
